<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Working from home<br>
                                                                                Couches</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                   <router-link :to="{ name: 'household-ergo-a45' }">
                                                                               
                                                                                    <em>Up
                                                                                        Next: </em> Household Ergo <img
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                   </router-link>
                                                                                        </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1637369498175"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <div class="heading_wrapper">
                                                            <h2>Are you a couch slouch?</h2>
                                                        </div>
                                                        <p>While it’s best to work at a desk or table top, you may
                                                            occasionally want to plop down on your couch or soft chair
                                                            to get some work done. And that is fine! But just make sure
                                                            that you are not slouching on that couch. Here are some tips
                                                            to help you:</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                                                            <div class="vc_column-inner vc_custom_1637191028527">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  no-margin">
                                                                        <div class="wpb_wrapper">
                                                                            <div class="heading-wrapper">
                                                                                <p><img loading="lazy" class=""
                                                                                        src="../../assets/images/airbnb/Couch-Coffee-Table-Trap-Icon-WFH.png"
                                                                                        width="56" height="56"></p>
                                                                                <h3>Avoiding the coffee table trap</h3>
                                                                            </div>
                                                                            <p>&nbsp;</p>
                                                                            <p>Do you use your coffee table as your
                                                                                desktop? If so, this promotes serious
                                                                                slouching and can wreak havoc on your
                                                                                neck, back, and shoulders. You can try
                                                                                using your TV as your monitor and place
                                                                                your keyboard and mouse on a lap desk
                                                                                (store-bought or homemade) or just use
                                                                                your laptop on a lap desk, preferably
                                                                                with enough room for an external mouse.
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
                                                            <div class="vc_column-inner vc_custom_1637191048177">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  no-margin">
                                                                        <div class="wpb_wrapper">
                                                                            <div class="heading-wrapper">
                                                                                <p><img loading="lazy" class=""
                                                                                        src="../../assets/images/airbnb/Couch-Pillows-icon-WFH.png"
                                                                                        width="56" height="56"></p>
                                                                                <h3>Throw pillows and towels</h3>
                                                                            </div>
                                                                            <p>&nbsp;</p>
                                                                            <p>While couches are meant for comfortable
                                                                                lounging, they don’t always foster good
                                                                                sitting posture. If the seat of your
                                                                                couch is too deep for you, try placing
                                                                                throw pillows or a rolled-up towel
                                                                                behind you for extra back support.
                                                                                Folded towels and small pillows also
                                                                                work wonders for added foot support if
                                                                                your feet don’t reach the floor.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from home journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                                <router-link :to="{ name: 'home-essentials-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Essentials-.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home---Home-Essentials-"
                                                                                       >
                                                                                </div>
                                                                                </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                              <router-link :to="{ name: 'home-essentials-a45' }">
                                                                            <h4>The essentials</h4>
                                                                            <p>Create a separate and distinct work space
                                                                                in your home.</p>
                                                                              </router-link>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'seating-a45' }">
                                                                              <div
                                                                                  class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                  <img width="750" height="530"
                                                                                      src="../../assets/images/airbnb/Airbnb-Work-From-Home-Seating.jpg"
                                                                                      class="vc_single_image-img attachment-full"
                                                                                      alt="" loading="lazy"
                                                                                      title="Airbnb-Work-From-Home-Seating"
                                                                                     >
                                                                              </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                          <router-link :to="{ name: 'seating-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Seating</h4>
                                                                                <p>It’s safe to say that you sit a lot
                                                                                    during your workday.</p>
                                                                            </div>
                                                                          </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'desktop-a45' }">
                                                                              <div
                                                                                  class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                  <img width="750" height="500"
                                                                                      src="../../assets/images/airbnb/Airbnb-Working-From-Home-Desktop.jpg"
                                                                                      class="vc_single_image-img attachment-full"
                                                                                      alt="" loading="lazy"
                                                                                      title="Airbnb-Working-From-Home---Desktop"
                                                                                      >
                                                                              </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'desktop-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Desktop</h4>
                                                                            <p>Learn where to place your work items for
                                                                                optimal comfort and productivity.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'couches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Work-From-Home-Couches-Landing-page-Footer-and-Page.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Work-From-Home-Couches---Landing-page,-Footer-and-Page"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'couches-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Couches</h4>
                                                                            <p>While it’s best to work at a desk or
                                                                                table top.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'household-ergo-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="892"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-Work-From-Home-Household-Ergo2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT = Work From Home Household Ergo2"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'household-ergo-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Household ergo</h4>
                                                                                <p>Ergonomics at home doesn’t have to be
                                                                                    fancy.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'home-stretches-a45' }">
                                                                               <div
                                                                                   class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                   <img width="750" height="500"
                                                                                       src="../../assets/images/airbnb/Airbnb-Work-From-Home-Home-Stretches.jpg"
                                                                                       class="vc_single_image-img attachment-full"
                                                                                       alt="" loading="lazy"
                                                                                       title="Airbnb-Work-From-Home---Home-Stretches"
                                                                                       >
                                                                               </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'home-stretches-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Home stretches</h4>
                                                                                <p>These stretches to help you feel good
                                                                                    while you work from home.</p>
                                                                            </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'couches-a45',
        mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
    }
</script>